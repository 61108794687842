//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'


class CuentasService {

  nuevoBanco(data) {
    return http.post('/cuenta/nuevoBanco', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  } 
  nuevoCaja(data) {
    return http.post('/cuenta/nuevoCaja', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  nuevoTipoCuentaBanco(data) {
    return http.post('/cuenta/nuevoTipoCuentaBanco', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  nuevoCuentaBancaria(data) {
    return http.post('/cuenta/nuevoCuentaBancaria', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  nuevoPuntoVenta(data) {
    return http.post('/cuenta/nuevoPuntoVenta', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  nuevoTipoPago(data) {
    return http.post('/cuenta/nuevoTipoPago', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  puntoVentaTransaccionNuevo(data) {
    return http.post('/cuenta/puntoVentaTransaccionNuevo', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  puntoVentaTransaccionAnular (data) {
    return http.post('/cuenta/puntoVentaTransaccionAnular', data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  
  
  bancoListar(datos) {
    return http.get("/cuenta/bancoListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  puntoVentaListar(datos) {
    return http.get("/cuenta/puntoVentaListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  puntoVentaCajaListar(datos) {
    return http.get("/cuenta/puntoVentaCajaListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  puntoVentaActualListar(datos) {
    return http.get("/cuenta/puntoVentaActualListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  puntoVentaTransaccionesActualListar(datos) {
    return http.get("/cuenta/puntoVentaTransaccionesActualListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  
  cajaListar(datos) {
    return http.get("/cuenta/cajaListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  tipoCuentaBancoListar(datos) {
    return http.get("/cuenta/tipoCuentaBancoListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  cuentaBancariaListar(datos) {
    return http.get("/cuenta/cuentaBancariaListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  tipoPagoListar(datos) {
    return http.get("/cuenta/tipoPagoListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  puntoVentaTipoPagoListar(datos) {
    return http.get("/cuenta/puntoVentaTipoPagoListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }

  cuentaBancariaMonedaListar(datos) {
    return http.get("/cuenta/cuentaBancariaMonedaListar", { params: datos })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
    
  subirImgBanco(data) {
    return http.post("/banco/img/upload", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    })
  } 
  
}

export default new CuentasService();